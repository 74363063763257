import React from 'react';

import './infoTable.css';
import EmptySpace from '../../components/emptySpace/EmptySpace';
import RoutesLinks from '../../config/RoutesLinks';
import PhotoGalleries from '../../config/PhotoGalleries';

const CLASS_NAME = 'info-table';

class InfoTable extends React.Component {
    render() {
        return (
          <React.Fragment>
            <div className={CLASS_NAME}>
                {this._renderQualityCert()}
                {this._renderEUBlock()}
            </div>
            <EmptySpace height={20}/>
          </React.Fragment>
        );
    }

    _renderQualityCert() {
        const content = (
            <p className={`${CLASS_NAME}__quality`}>
                Jsme držiteli CERTIFIKÁTU systému managementu kvality dle normy ČSN EN ISO 9001:2009
                se zohledněním vyšších požadavků na jakost při svařování dle ČSN EN ISO 3834-2:2006.
                Společnost Armostav Místek, s.r.o. se mimo jiné v souvislosti s přijetím výše
                uvedených norem, zavázala dodržovat pravidla, která upravuje TP 193 pro svařování
                betonářských ocelí, schválený MD – 0I č.j. 536/08-910-IPK/1
            </p>
        );

        return this._renderInfoBlock(content, '/files/certifikat_kvality.jpeg');
    }

    _renderEUBlock() {
        const content = (
          <div className={`${CLASS_NAME}__eu-block`}>
            <img className={`${CLASS_NAME}__eu-flag--desktop`} alt="EU" src="/files/eu_flag.png" loading="lazy" />
            <div>
              <div className={`${CLASS_NAME}__title-wrapper`}>
                <img className={`${CLASS_NAME}__eu-flag--mobile`} alt="EU" src="/files/eu_flag.png" loading="lazy" />
                <div className={`${CLASS_NAME}__eu-title`}>EVROPSKÁ UNIE - EVROPSKÝ FOND PRO REGIONÁLNÍ ROZVOJ</div>
              </div>
              <div className={`${CLASS_NAME}__eu-text`}>Naše firma realizovala projekty Úspory energie ve společnosti ARMOSTAV MÍSTEK, s.r.o. (CZ.01.3.10/0.0/0.0/20_370/0024623) a Energetické úspory na technologiích ve společnosti ARMOSTAV MÍSTEK, s.r.o. (CZ.01.3.10/0.0/0.0/20_370/0026013)</div>
              <div className={`${CLASS_NAME}__eu-click`}>Pro bližší informace o projektech klikněte zde</div>

            </div>
          </div>
        );

        return this._renderInfoBlock(content, '/files/eu_certifikaty.png');
    }

    _renderInfoBlock(content, link, newTab = true) {
        return (
            <div className={`${CLASS_NAME}__block`}>
                {newTab && (
                    <a target="_blank" rel="noreferrer" href={link}>
                        <span className={`${CLASS_NAME}__block__content`}>{content}</span>
                    </a>
                )}
                {!newTab && (
                    <a href={link}>
                        <span className={`${CLASS_NAME}__block__content`}>{content}</span>
                    </a>
                )}
            </div>
        );
    }
}

export default InfoTable;
