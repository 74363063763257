import React from 'react';
import EmptySpace from '../components/emptySpace/EmptySpace';
import RoutesLinks from '../config/RoutesLinks';
import CoreInfo from '../components/contacts/coreInfo/CoreInfo';
import ContactsCarousel from '../components/contacts/contactsCarousel/ContactsCarousel';
import PageTitleLine from '../components/pageTitleLine/PageTitleLine';
import EmptySpaceBigScreen from "../components/emptySpaceBigScreen/EmptySpaceBigScreen"

export default function kontakty() {
    return (
        <>
            <PageTitleLine idTag={RoutesLinks.KONTAKTY} title={'Kontakty'} />
            <EmptySpace height={80} noMobile={true} />
            {<CoreInfo />}
            <EmptySpace height={65} noMobile={true} />
            <ContactsCarousel />
            {/*             <Contacts />*/}
            <EmptySpace height={100} />
            <EmptySpaceBigScreen height={60} />
        </>
    );
}
