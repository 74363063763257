import React from 'react';
import EmptySpace from '../components/emptySpace/EmptySpace';
import Prices from '../components/prices/Prices';
import RoutesLinks from '../config/RoutesLinks';
import Offers from '../components/offers/Offers';
import PageTitleLine from '../components/pageTitleLine/PageTitleLine';

export default function sluzby() {
    return (
        <div>
            <PageTitleLine idTag={RoutesLinks.SLUZBY} title={'Služby'} />
            <EmptySpace height={60} />

            <Prices />
            <EmptySpace height={50} />
            <Offers />
            <EmptySpace height={100} />
        </div>
    );
}
