/**
 * default value is require for template render
 */

export default function(sinceYear) {
  const now = new Date()
  const since = new Date();
  since.setFullYear(sinceYear)

  return now.getFullYear() - since.getFullYear();
}
