import React from 'react';
import EmptySpace from '../components/emptySpace/EmptySpace';
import About from '../components/about/About';
import RoutesLinks from '../config/RoutesLinks';
import PageTitleLine from '../components/pageTitleLine/PageTitleLine';

export default function onas() {
    return (
        <>
            <PageTitleLine idTag={RoutesLinks.O_NAS} title={'O společnosti'} />
            <EmptySpace height={20} noMobile={true} />
            <About />
            <EmptySpace height={130} noMobile={true} />
        </>
    );
}
