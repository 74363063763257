import React from 'react';

import './prices.css';

const CLASS_NAME = 'prices';

class Prices extends React.Component {
    render() {
        return (
            <div className={CLASS_NAME}>
                <p>
                    Ceny zpracujeme každému na míru dle projektové dokumentace, aktuální ceny
                    vstupního materiálu a použitých průměrů. Pro informaci neváhejte zavolat na
                    telefonní číslo +420 731 416 462 nebo napsat na mail{' '}
                    <a href={'mailto:rohel@armostav.cz'}>rohel@armostav.cz</a>
                </p>
            </div>
        );
    }
}

export default Prices;
