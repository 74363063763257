import React from 'react';
import InfoTable from '../components/infoTable/InfoTable';
import EmptySpace from '../components/emptySpace/EmptySpace';
import Footer from '../components/footer/Footer';
import sluzby from '../page_block/sluzby';
import mapa from '../page_block/mapa';
import kontakty from '../page_block/kontakty';
import fotogalerie from '../page_block/fotogalerie';
import onas from '../page_block/onas';
import home from '../page_block/home';
import '../globals/baseStyles.css';
import { Helmet } from 'react-helmet';

export default function index() {
    return (
        <>
            <Helmet htmlAttributes={{ lang: 'cs' }}>
                <title>Armostav Místek, s.r.o.</title>
                <meta
                    name="description"
                    content="ARMOSTAV MÍSTEK, s r.o. je specialista na zpracování betonářské výztuže s dlouholetou tradicí a bohatými zkušenostmi."
                />
              <meta
                    property="og:title"
                    content="Armostav Místek, s.r.o."
                />
              <meta
                    property="og:image"
                    content="https://www.armostav.cz/logo-removebg-preview.png"
                />
            </Helmet>
            <title>Armostav Místek, s.r.o.</title>
            {home()}
            <div style={{ backgroundColor: '#EFEFEF' }}>{onas()}</div>
            {sluzby()}
            <div style={{ backgroundColor: '#EFEFEF' }}>{fotogalerie()}</div>
            {kontakty()}
            <div style={{ backgroundColor: '#EFEFEF' }}>{mapa()}</div>
            <EmptySpace height={70} noMobile={true} />
            <InfoTable />
            <EmptySpace height={10} />
            <Footer />
        </>
    );
}
