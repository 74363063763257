import React from 'react';
import YearsSince from "../../utils/YearsSince"

import './about.css';

const CLASS_NAME = 'about';

class About extends React.Component {
    // 19. nedělitelná mezera
    // 23 let na trhu automaticky posun

  constructor() {
    super();
    this.state = {
      firstRender: true,
    }
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      setTimeout(() => {
        this.setState({
          firstRender: false,
        });
      }, 1);
    }
  }


    render() {
        const yearsOnMarket = this.state.firstRender ? '23' : YearsSince(1998);

        return (
            <div className={CLASS_NAME}>
                <h3>{yearsOnMarket} LET NA TRHU</h3>
                <p>
                    Společnost byla založena 19. února 1998 jako{' '}
                    <b>nástupnická organizace původní firmy</b>, pracující pod jménem zakladatele v
                    rámci živnostenského oprávnění.
                </p>
                <h3>V ČEM VYNIKÁME</h3>
                <p>
                    Základním předmětem podnikání je výroba a montáž výztuží z betonářských ocelí. V
                    čele společnosti Armostav Místek s.r.o. jsou dva jednatelé a ředitel
                    společnosti, kteří mají <b>dlouholetou praxi</b> v oboru. Provozuje{' '}
                    <b>vlastní ohýbárnu </b>
                    vybavenou <b>moderními technologiemi</b> poskytujícími{' '}
                    <b>široký záběr možností zpracování ocelí v drátu a tyčích</b> dodaných
                    zákazníkem nebo vlastních. V návaznosti na výrobu nabízíme a realizujeme také{' '}
                    <b>pokládku výztuží z betonářských ocelí.</b>
                </p>
                <h3>ČÍM SE ŘÍDÍME</h3>
                <p>
                    Klademe velký důraz na výběr zaměstnanců, na <b>dodržení termínů</b> a
                    požadovanou <b>kvalitu</b> dodávek, což se odráží na <b>spokojenosti</b>{' '}
                    dosavadních odběratelů.
                </p>

                {/*                <img src={'/images/management_headquarter.jpeg'} />*/}
            </div>
        );
    }
}

export default About;
