import React from 'react';

import './peopleBox.css';
import PeopleBoxTypes from '../../../config/PeopleBoxTypes';

const CLASS_NAME = 'people-box';

class PeopleBox extends React.Component {
    render() {
        return <div className={CLASS_NAME}>{this._renderItem()}</div>;
    }

    _renderItem() {
        const box = this.props.box || null;

        switch (box) {
            case PeopleBoxTypes.MANAGEMENT:
                return this._renderManagement();
            case PeopleBoxTypes.PRODUCTION:
                return this._renderProduction();
            case PeopleBoxTypes.CONSTRUCTION:
                return this._renderConstruction();
            case PeopleBoxTypes.ADMINISTRATION:
                return this._renderAdministration();
            case PeopleBoxTypes.SLOVAKIA:
                return this._renderSlovakia();
            default:
                return null;
        }
    }

    _renderSlovakia() {
        const tomek = this._renderPersonBox(
            'Jednatel',
            'Miroslav Tomek',
            '+420 603 238 373',
            'tomek@armostav.cz'
        );
        const rohel = this._renderPersonBox(
            'Ředitel společnosti',
            'Ing. Jiří Rohel',
            '+420 731 416 462',
            'rohel@armostav.cz'
        );

        return (
            <div className={`${CLASS_NAME}__box`}>
                {tomek}
                {rohel}
            </div>
        );
    }

    _renderManagement() {
        const tomek = this._renderPersonBox(
            'Jednatel',
            'Miroslav Tomek',
            '+420 603 238 373',
            'tomek@armostav.cz'
        );
        const petros = this._renderPersonBox(
            'Jednatel',
            'Pavel Petroš',
            '+420 603 847 909',
            'petros@armostav.cz'
        );
        const rohel = this._renderPersonBox(
            'Ředitel společnosti',
            'Ing. Jiří Rohel',
            '+420 731 416 462',
            'rohel@armostav.cz'
        );

        return (
            <div className={`${CLASS_NAME}__box`}>
                {tomek}
                {petros}
                {rohel}
            </div>
        );
    }

    _renderProduction() {
        const jurcak = this._renderPersonBox(
            'Vedoucí provozovny',
            'Dominik Jurčák',
            '+420 731 493 100',
            'jurcak@armostav.cz'
        );
        const barina = this._renderPersonBox(
            'Mistr výroby',
            'Petr Bařina',
            '+420 731 447 240',
            'ohybarna@armostav.cz'
        );
        const ruzicka = this._renderPersonBox(
            'Příprava, výroba',
            'Tomáš Růžička',
            '+420 731 447 240',
            'ohybarna@armostav.cz'
        );

        return (
            <div className={`${CLASS_NAME}__box`}>
                {jurcak}
                {barina}
                {ruzicka}
            </div>
        );
    }

    _renderConstruction() {
        const benes = this._renderPersonBox(
            'Realizace staveb',
            'Ing. Miroslav Beneš',
            '+420 605 249 372',
            'benes@armostav.cz'
        );
        const petros = this._renderPersonBox(
            'Jednatel',
            'Pavel Petroš',
            '+420 603 847 909',
            'petros@armostav.cz'
        );

        return (
            <div className={`${CLASS_NAME}__box`}>
                {benes}
                {petros}
            </div>
        );
    }

    _renderAdministration() {
        const machova = this._renderPersonBox(
            'Administrativa',
            'Lenka Machová',
            '+420 606 604 499',
            'machova@armostav.cz'
        );
        const cechova = this._renderPersonBox(
            'Administrativa',
            'Zdenka Čechová',
            '+420 731 447 242',
            'cechova@armostav.cz'
        );

        const tomkova = this._renderPersonBox(
            'Atesty, certifikace',
            'Barbora Tomková',
            '+420 731 447 239',
            'tomkova@armostav.cz'
        );
        const pavlikova = this._renderPersonBox(
            'Administrativa, sklad',
            'Iveta Pavlíková',
            '+420 731 125 682',
            'pavlikova@armostav.cz'
        );

        return (
            <div className={`${CLASS_NAME}__box`}>
                {machova}
                {cechova}
                {tomkova}
                {pavlikova}
            </div>
        );
    }

    _renderPersonBox(role, name, tel, mail) {
        return (
            <div className={`${CLASS_NAME}__person`}>
                {/*                <img className={`${CLASS_NAME}__person-image`} src={'/images/person.jpg'} />*/}
                <p className={`${CLASS_NAME}__role`}>{role}</p>
                <p className={`${CLASS_NAME}__name`}>{name}</p>
                <a className={`${CLASS_NAME}__mail`} href={`mailto:${mail}`}>
                    {this._renderMailIcon()}
                    {mail}
                </a>
                <p className={`${CLASS_NAME}__tel`}>
                    {this._renderPhoneIcon()}
                    {tel}
                </p>
            </div>
        );
    }

    _renderPhoneIcon() {
        return (
            <svg
                className="phone-icon"
                viewBox="0 0 513.64 513.64"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <g>
                    <g>
                        <path
                            d="M499.66,376.96l-71.68-71.68c-25.6-25.6-69.12-15.359-79.36,17.92c-7.68,23.041-33.28,35.841-56.32,30.72
          c-51.2-12.8-120.32-79.36-133.12-133.12c-7.68-23.041,7.68-48.641,30.72-56.32c33.28-10.24,43.52-53.76,17.92-79.36l-71.68-71.68
          c-20.48-17.92-51.2-17.92-69.12,0l-48.64,48.64c-48.64,51.2,5.12,186.88,125.44,307.2c120.32,120.32,256,176.641,307.2,125.44
          l48.64-48.64C517.581,425.6,517.581,394.88,499.66,376.96z"></path>
                    </g>
                </g>
            </svg>
        );
    }

    _renderMailIcon() {
        return (
            <svg
                className="mail-icon"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 512 512">
                <g>
                    <g>
                        <path
                            d="M467,76H45C20.137,76,0,96.262,0,121v270c0,24.885,20.285,45,45,45h422c24.655,0,45-20.03,45-45V121
			C512,96.306,491.943,76,467,76z M460.698,106c-9.194,9.145-167.415,166.533-172.878,171.967c-8.5,8.5-19.8,13.18-31.82,13.18
			s-23.32-4.681-31.848-13.208C220.478,274.284,64.003,118.634,51.302,106H460.698z M30,384.894V127.125L159.638,256.08L30,384.894z
			 M51.321,406l129.587-128.763l22.059,21.943c14.166,14.166,33,21.967,53.033,21.967c20.033,0,38.867-7.801,53.005-21.939
			l22.087-21.971L460.679,406H51.321z M482,384.894L352.362,256.08L482,127.125V384.894z"
                        />
                    </g>
                </g>
            </svg>
        );
    }
}

export default PeopleBox;
