import React from 'react';
import Navigation from '../components/navigation/Navigation';
import EmptySpace from '../components/emptySpace/EmptySpace';
import RoutesLinks from '../config/RoutesLinks';
import Promo from '../components/promo/Promo';
import MainFocuses from '../components/mainFocues/MainFocuses';

export default function home() {
    return (
        <>
            <Navigation
                placeholderId={RoutesLinks.HOME}
                location={RoutesLinks.HOME}
                placeholder={false}
            />
            <Promo />
            <EmptySpace height={80} noMobile={true} />
            <MainFocuses />
            <EmptySpace height={100} noMobile={true} />
        </>
    );
}
