import React from 'react';

import './offerBlock.css';
import Icon from '../icon/Icon';

const CLASS_NAME = 'offer-block';

class OfferBlock extends React.Component {
    render() {
        return (
            <li className={CLASS_NAME}>
                <div className={`${CLASS_NAME}__content`}>{this.props.content}</div>
                <div className={`${CLASS_NAME}__icon`}>
                    <Icon icon={this.props.icon} />
                </div>
            </li>
        );
    }
}

export default OfferBlock;
