import React from 'react';

import './coreInfo.css';
import EmptySpace from '../../emptySpace/EmptySpace';

const CLASS_NAME = 'core-info';

class CoreInfo extends React.Component {
    render() {
        return (
            <div className={CLASS_NAME}>
                <table className={`${CLASS_NAME}__table`}>
                    <tbody>
                        <tr>
                            {this._renderAddress()}
                            {this._renderSeparator()}
                            {this._renderContact()}
                            {this._renderSeparator()}
                            {this._renderBank()}
                        </tr>
                    </tbody>
                </table>
                <EmptySpace height={40} />
                <table className={`${CLASS_NAME}__table`}>
                    <tbody>
                        <tr>
                            {this._renderAddressSK()}
                            {this._renderSeparator('SK')}
                            {this._renderMobileTitle('KONTAKTNÍ INFORMACE')}
                            {this._renderContactSK()}
                            {this._renderSeparator('SK')}
                            {this._renderMobileTitle('ZÚČTOVACÍ DATA')}
                            {this._renderBankSK()}
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    _renderMobileTitle(text = '') {
        return <td className={`${CLASS_NAME}__mobile-title`}>{text}</td>;
    }

    _renderSeparator(type = '') {
        let styleClass = `${CLASS_NAME}__separator`;

        if (type) {
            styleClass += ` ${CLASS_NAME}__separator--${type}`;
        }
        return (
            <td>
                <div className={styleClass} />
            </td>
        );
    }

    _renderAddress() {
        return (
            <td className={`${CLASS_NAME}__address`}>
                <div className={`${CLASS_NAME}__address-content`}>
                    <div className={`${CLASS_NAME}__logo`}>ARMOSTAV Místek, s.r.o.</div>
                    <p>Vedení společnosti: Collo-louky 2151, 738 01 Frýdek-Místek</p>
                    <p>Provozovna: U Řeky 925, 720 00 Ostrava-Hrabová</p>
                </div>
            </td>
        );
    }

    _renderAddressSK() {
        return (
            <td className={`${CLASS_NAME}__address`}>
                <div className={`${CLASS_NAME}__address-content`}>
                    <div className={`${CLASS_NAME}__logo`}>ARMOSTAV SK, s.r.o.</div>
                    <p>022 01 Čadca, Horná 116</p>
                </div>
            </td>
        );
    }

    _renderContact() {
        return (
            <td>
                <div className={`${CLASS_NAME}__contacts-content`}>
                    <div className={`${CLASS_NAME}__sub-title`}>KONTAKTNÍ INFORMACE</div>
                    <table className={`${CLASS_NAME}__contact-table`}>
                        <tbody>
                            <tr className={`${CLASS_NAME}__row`}>
                                <td>Administrativa:</td>
                                <td>+420 737 732 008</td>
                            </tr>
                            <tr className={`${CLASS_NAME}__row`}>
                                <td>Poptávky:</td>
                                <td>
                                    +420 731 416 462
                                    {/*<a href={'mailto:rohel@armostav.cz'}>rohel@armostav.cz</a>*/}
                                </td>
                            </tr>
                            <tr className={`${CLASS_NAME}__row`}>
                                <td>Výroba:</td>
                                <td>
                                    +420 731 447 240
                                    {/*<a href={'mailto:ohybarna@armostav.cz'}>ohybarna@armostav.cz</a>*/}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </td>
        );
    }

    _renderContactSK() {
        return (
            <td>
                <div className={`${CLASS_NAME}__contacts-content`}>
                    <table className={`${CLASS_NAME}__contact-table`}>
                        <tbody>
                            <tr className={`${CLASS_NAME}__row`}>
                                <td>Administrativa:</td>
                                <td>+420 737 732 008</td>
                            </tr>
                            <tr className={`${CLASS_NAME}__row`}>
                                <td>Jednatel:</td>
                                <td>+420 603 238 373</td>
                            </tr>
                            <tr className={`${CLASS_NAME}__row`}>
                                <td>Zakázky:</td>
                                <td>+420 731 416 462</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </td>
        );
    }

    _renderBank() {
        return (
            <td>
                <div className={`${CLASS_NAME}__bank-content`}>
                    <div className={`${CLASS_NAME}__sub-title`}>ZÚČTOVACÍ DATA</div>
                    <div className={`${CLASS_NAME}__bank-info`}>
                        <table className={`${CLASS_NAME}__bank-table`}>
                            <tbody>
                                <tr className={`${CLASS_NAME}__row`}>
                                    <td>IČO:</td>
                                    <td>25391062</td>
                                </tr>
                                <tr className={`${CLASS_NAME}__row`}>
                                    <td>DIČ:</td>
                                    <td>CZ25391062</td>
                                </tr>
                                <tr className={`${CLASS_NAME}__row`}>
                                    <td colSpan={2}>Bank.spojení: Komerční banka a.s.</td>
                                </tr>
                                <tr className={`${CLASS_NAME}__row`}>
                                    <td colSpan={2}>Číslo účtu: 19-3712580297/0100</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </td>
        );
    }

    _renderBankSK() {
        return (
            <td>
                <div className={`${CLASS_NAME}__bank-content`}>
                    <div className={`${CLASS_NAME}__bank-info`}>
                        <table className={`${CLASS_NAME}__bank-table`}>
                            <tbody>
                                <tr className={`${CLASS_NAME}__row`}>
                                    <td>IČ:</td>
                                    <td>45429391</td>
                                </tr>
                                <tr className={`${CLASS_NAME}__row`}>
                                    <td>DIČ:</td>
                                    <td>SK2022975603</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </td>
        );
    }
}

export default CoreInfo;
