import React from 'react';

import './mainFocuses.css';
import Icon from '../icon/Icon';
import IconTypes from '../icon/IconTypes';

const CLASS_NAME = 'main-focuses';

class MainFocuses extends React.Component {
    render() {
        return (
            <div className={CLASS_NAME}>
                <h2 className={`${CLASS_NAME}__title`} />
                <div className={`${CLASS_NAME}__wrapper`}>
                    {this._focusItem(
                        IconTypes.HPSecond,
                        <span>
                            Vlastní <span className={`${CLASS_NAME}__highlight`}>ohýbárna</span>,
                            vybavená{' '}
                            <span className={`${CLASS_NAME}__highlight`}>
                                moderními technologiemi
                            </span>
                        </span>
                    )}
                    {this._focusItem(
                        IconTypes.HPThird,
                        <span>
                            Široký záběr{' '}
                            <span className={`${CLASS_NAME}__highlight`}>zpracování oceli</span> v
                            drátu a tyčích
                        </span>
                    )}
                    {this._focusItem(
                        IconTypes.HPFirst,
                        <span>
                            Velký důraz na{' '}
                            <span className={`${CLASS_NAME}__highlight`}>
                                výběr zaměstnanců a dodržení termínů
                            </span>
                        </span>
                    )}
                </div>
            </div>
        );
    }

    _focusItem(icon, content) {
        return (
            <div className={`${CLASS_NAME}__item`}>
                <Icon icon={icon} />
                <div className={`${CLASS_NAME}__content`}>{content}</div>
            </div>
        );
    }
}

export default MainFocuses;
