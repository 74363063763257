import React from 'react';
import Carousel, { arrowsPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import Arrow from '../arrow/Arrow';
import YearsSince from "../../utils/YearsSince"
import './promo.css';
import { StaticImage } from 'gatsby-plugin-image';

const IMAGE_SWITCH_INTERVAL = 5000;
const SHOW_TEXT_INTERVAL = 900;
const CLASS_NAME = 'promo';

class Promo extends React.Component {
    constructor() {
        super();
        this.state = {
            firstRender: true,
            value: 0,
            slides: this.renderSlides(true),
        };

        this.textUpdate = this.textUpdate.bind(this);
        this.onchange = this.onchange.bind(this);
        this.moveState = this.moveState.bind(this);
        this.onArrowClick = this.onArrowClick.bind(this);
    }

    renderSlides(firstRender = false) {
        const yearsOnMarket = firstRender ? '23' : YearsSince(1998);
        const yearsOnSlovakia = firstRender ? '10' : YearsSince(2011);

      return(
        [
          this._renderFirstStaticItem(
            0,
            <>
              <span>{'Působíme na českém trhu'}</span>
              <span className={`${CLASS_NAME}__title--white`}>{` ${yearsOnMarket} let`}</span>
              <span>{' a '}</span>
              <br />
              <span className={`${CLASS_NAME}__title--white`}>{`${yearsOnSlovakia} let `}</span>
              <span>{' na Slovensku'}</span>
            </>
          ),
        this._renderSecondStaticItem(1),
      ])
    }

    componentDidMount() {
        if (typeof window !== `undefined`) {
            this._rotationInterval = setInterval(this.moveState, IMAGE_SWITCH_INTERVAL);

            this.textUpdate(0);

            setTimeout(() => {
                this.setState({
                    firstRender: false,
                    slides: this.renderSlides(false),
                });
            }, 1);
        }
    }

    componentWillUnmount() {
        clearInterval(this._rotationInterval);
    }

    moveState() {
        const nextItem =
            this.state.value === (this.state.slides.length - 1) * 100 ? 0 : this.state.value + 1;

        this.textUpdate(nextItem);
        this.setState({
            value: nextItem,
        });
    }

    onchange(value) {
        this.setState({ value });
        this.textUpdate(value);
    }

    textUpdate(value) {
        setTimeout(() => {
            this.setState({
                showText: value % this.state.slides.length,
            });
        }, SHOW_TEXT_INTERVAL);
    }

    onArrowClick() {
        clearInterval(this._rotationInterval);
    }

    render() {
        const { showText } = this.state;
        return (
            <div className={`${CLASS_NAME} ${CLASS_NAME}__value-${showText}`}>
                {this.state.firstRender && (
                    <div className={`${CLASS_NAME}__placeholder`}>{this.state.slides[0]}</div>
                )}
                {!this.state.firstRender && this._renderCarousel()}
            </div>
        );
    }

    _renderCarousel() {
        return (
            <Carousel
                plugins={[
                    {
                        resolve: arrowsPlugin,
                        options: {
                            arrowLeft: this._renderArrow('left'),
                            arrowRight: this._renderArrow('right'),
                            addArrowClickHandler: true,
                        },
                    },
                    'infinite',
                ]}
                animationSpeed={1000}
                value={this.state.value}
                slides={this.state.slides}
                onChange={this.onchange}
            />
        );
    }

    _renderFirstStaticItem(index, text) {
        return (
            <div className={`${CLASS_NAME}__item`}>
                <div className={`${CLASS_NAME}__title ${CLASS_NAME}__title-${index}`}>{text}</div>
                <StaticImage
                    className={`${CLASS_NAME}__item__image ${CLASS_NAME}__item__image--mobile-only`}
                    src="../../../static/images/promo/1_mobile.jpg"
                    alt={'provozovna'}
                />
                <StaticImage
                    className={`${CLASS_NAME}__item__image ${CLASS_NAME}__item__image--desktop-only`}
                    src="../../../static/images/promo/1.jpg"
                    alt={'provozovna'}
                />
            </div>
        );
    }

    _renderSecondStaticItem(index, text) {
        return (
            <div className={`${CLASS_NAME}__item`}>
                {/*<div className={`${CLASS_NAME}__title ${CLASS_NAME}__title-${index}`}>{text}</div>*/}
                <StaticImage
                    className={`${CLASS_NAME}__item__image ${CLASS_NAME}__item__image--mobile-only`}
                    src="../../../static/images/promo/2_mobile.jpg"
                    alt={'nové vybavení'}
                />
                <StaticImage
                    className={`${CLASS_NAME}__item__image ${CLASS_NAME}__item__image--desktop-only`}
                    src="../../../static/images/promo/2.jpg"
                    alt={'nové vybavení'}
                />
            </div>
        );
    }

    _renderArrow(rotation) {
        return (
            <div onClick={this.onArrowClick} className={`${CLASS_NAME}__arrow`}>
                <Arrow rotation={rotation} />
            </div>
        );
    }
}

export default Promo;
