import React from 'react';

import './offers.css';
import OfferBlock from './OfferBlock';
import IconTypes from '../icon/IconTypes';

const CLASS_NAME = 'offers';

class Offers extends React.Component {
    render() {
        return (
            <div className={CLASS_NAME}>
                <ul className={`${CLASS_NAME}__listing`}>
                    {this._renderOffers(IconTypes.BRIDGE, 'Konstrukce mostů')}
                    {this._renderOffers(IconTypes.MONOLIT, 'Monolitické stavby')}
                    {this._renderOffers(IconTypes.COOLING_TOWER, 'Chladící věže')}
                    {/*                    {this._renderOffers(IconTypes.TUNNEL, 'Tunely')}*/}
                    {this._renderOffers(IconTypes.PILOT, 'Piloty')}
                    {this._renderOffers(IconTypes.SUBWAY, 'Podchody')}
                </ul>
            </div>
        );
    }

    _renderOffers(icon, content) {
        return <OfferBlock icon={icon} content={content} />;
    }
}

export default Offers;
