import React from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import './contactsCarousel.css';
import PeopleBox from '../personsBox/PeopleBox';
import PeopleBoxTypes from '../../../config/PeopleBoxTypes';
import IconTypes from '../../icon/IconTypes';
import Icon from '../../icon/Icon';

const CLASS_NAME = 'contacts-carousel';

class ContactsCarousel extends React.Component {
    constructor() {
        super();
        this.state = {
            firstRender: true,
            value: 0,
            slides: [
                <PeopleBox box={PeopleBoxTypes.MANAGEMENT} />,
                <PeopleBox box={PeopleBoxTypes.ADMINISTRATION} />,
                <PeopleBox box={PeopleBoxTypes.PRODUCTION} />,
                <PeopleBox box={PeopleBoxTypes.CONSTRUCTION} />,
                <PeopleBox box={PeopleBoxTypes.SLOVAKIA} />,
            ],
        };

        this.onchange = this.onchange.bind(this);
        this.moveState = this.moveState.bind(this);
        this._renderNavigationCategory = this._renderNavigationCategory.bind(this);
    }

    componentDidMount() {
        if (typeof window !== `undefined`) {
            setTimeout(() => {
                this.setState({
                    firstRender: false,
                });
            }, 1);
        }
    }

    moveState() {
        const nextItem = this.state.value;

        this.setState({
            value: nextItem,
        });
    }

    onchange(value) {
        this.setState({ value });
    }

    render() {
        return (
            <div className={`${CLASS_NAME}`}>
                {this._renderMobileTitle()}
                {this._renderNavigation()}
                {this.state.firstRender && (
                    <div className={`${CLASS_NAME}__placeholder`}>
                        {this.state.slides[this.state.value]}
                    </div>
                )}
                {!this.state.firstRender && this._renderCarousel()}
                {this._renderMobileItem()}
            </div>
        );
    }

    _renderMobileTitle() {
        return (
            <div className={`${CLASS_NAME}__mobile ${CLASS_NAME}__navigation-info`}>
                Klikněte na kategorii kontaktů, kterou hledáte:
            </div>
        );
    }

    _renderMobileItem() {
        return <div className={`${CLASS_NAME}__mobile`}>{this.state.slides[this.state.value]}</div>;
    }

    _renderNavigation() {
        const items = [
            'Vedení společnosti',
            'Administrativa',
            'Výroba',
            'Realizace Staveb',
            'Slovensko',
        ];
        return (
            <div className={`${CLASS_NAME}__navigation`}>
                {items.map((item, index) => this._renderNavigationCategory(item, index))}
            </div>
        );
    }

    _renderNavigationCategory(content, index) {
        let cssClasses = `${CLASS_NAME}__navigation-category`;
        const page = Math.floor(this.state.value / this.state.slides.length);
        cssClasses +=
            this.state.value - page * 5 === index
                ? ` ${CLASS_NAME}__navigation-category--selected`
                : '';

        const newValue = page * 5 + index;

        return (
            <span key={content} className={cssClasses} onClick={() => this.onchange(newValue)}>
                {content}
            </span>
        );
    }

    _renderCarousel() {
        return (
            <div className={`${CLASS_NAME}__carousel-wrapper`}>
                <div
                    className={`${CLASS_NAME}__arrow ${CLASS_NAME}__arrow--left`}
                    onClick={() => {
                        this.onchange(this.state.value - 1);
                    }}>
                    <Icon icon={IconTypes.LEFT_ARROW} />
                </div>
                <Carousel
                    plugins={['infinite']}
                    animationSpeed={0}
                    value={this.state.value}
                    slides={this.state.slides}
                    onChange={this.onchange}
                />
                <div
                    className={`${CLASS_NAME}__arrow`}
                    onClick={() => {
                        this.onchange(this.state.value + 1);
                    }}>
                    <Icon icon={IconTypes.RIGHT_ARROW} />
                </div>
            </div>
        );
    }
}

export default ContactsCarousel;
